import { MutableRefObject, RefObject, useEffect, useState } from 'react';


export const useScrollForMoreDetails = (
    containerRef: MutableRefObject<HTMLElement | undefined> | RefObject<HTMLElement | undefined> | undefined,
    targetElementRef: MutableRefObject<HTMLElement | null | undefined>,
    footerElementRef: MutableRefObject<HTMLElement | null | undefined>,
) => {
    const [showScrollForMoreDetails, setShowScrollForMoreDetails] = useState(true);
    const scrollToTargetElement = () => {
        targetElementRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        let observer: IntersectionObserver | null = null;

        // use an interval to ensure the refs have already been added to the DOM
        const interval = setInterval(() => {
            if (!targetElementRef.current || !containerRef?.current || !footerElementRef.current) return;

            observer = new IntersectionObserver(entries => {
                for (const entry of entries) {
                    if (entry.target !== targetElementRef.current) continue;
    
                    setShowScrollForMoreDetails(!entry.isIntersecting);
                }
            }, { threshold: 1, root: containerRef.current, rootMargin: `0px 0px ${-footerElementRef.current.offsetHeight}px 0px` });

            observer.observe(targetElementRef.current);

            clearInterval(interval);
        }, 10);

        return () => {
            setShowScrollForMoreDetails(true);
            clearInterval(interval);
            observer?.disconnect();
        };
    }, [containerRef, footerElementRef, targetElementRef]);

    return {
        showScrollForMoreDetails,
        scrollToTargetElement,
    };
};